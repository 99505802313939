import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        Header: 'User Name',
        accessor: 'userName',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ row, value }) => {
            const nsUserId = row.original.nsUserId
            if(!nsUserId) return value
            return <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}/users/${nsUserId}`}>
                {value}
            </Link>
        }
    },
    {
        Header: 'User Email',
        accessor: 'userEmail',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
      
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
        Cell: ({ row, value }) => (
            <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                {value}
            </Link>
        ),
    },
    {
        Header: 'Multi-Company User',
        /**
         *
         * @param {Object} row
         * @param {boolean | null} row.isMultiCompanyUser
         * @returns 'Y'|'N'
         */
        accessor: (row) => row.isMultiCompanyUser === true ? 'Y' : 'N',
        id: 'isMultiCompanyUser',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Invite Details',
        accessor: 'domainMismatchReasonCode',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Invited Date',
        accessor: (row) => new Date(row.invitedDtm).toLocaleDateString(),
        id: 'invitedDtm',
        className: generalStyles.tableCell,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'Invite Source',
        accessor: 'invitationSource',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
]