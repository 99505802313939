import generalStyles from '@/components/General/Styles.module.scss';

function displayType(uglyType) {
    if (uglyType) {
        // eslint-disable-next-line
        if (typeof uglyType === 'string') {
            return uglyType.replaceAll('_', ' ');
        } else {
            return uglyType;
        }
    } else {
        return '';
    }
}
export const COLUMNS = [
    {
        Header: 'Sku',
        accessor: 'sku',
        id: 'sku',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true,
    },
    {
        Header: 'Product',
        accessor: 'title',
        id: 'title',
        Cell: ({ value }) => displayType(value),
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Type',
        accessor: 'type',
        id: 'type',
        Cell: ({ value }) => displayType(value),
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Quantity',
        accessor: 'quantity',
        id: 'quantity',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true, 
    },
    {
        Header: 'Contract Start Date',
        accessor: 'contractStartDate',
        id: 'contractStartDate',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true, 
    },
    {
        Header: 'Expiry Date',
        accessor: 'contractEndDate',
        id: 'contractEndDate',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true, 
    },
    {
        Header: 'Source',
        accessor: 'source',
        id: 'source',
        className: `${generalStyles.tableCell}`,
        disableFilters: true, 
    }
]