import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { getCsrAuditLogsReport } from '@/services/api/report.service';
import { useEffect, useMemo, useState } from 'react';
import { handleApiError } from '../General/HandleApiError';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { Link } from 'react-router-dom';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';

export function CsrAuditLogsReport() {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    async function loadCsrAuditLogs() {
        setReportApiStatus(API_STATES.loading);
        const result = await getCsrAuditLogsReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    const csrAuditLogsReportColumns = useMemo(
        () => [
            {
                Header: 'Timestamp',
                accessor: 'timestamp',
                id: 'timestamp',
                Cell: ({ value }) => value ? new Date(value).toLocaleString() : '',
                filter: FILTER_TYPES.dateRange,
                Filter: DateRangeColumnFilter,
                className: generalStyles.tableCell,
            },
            {
                Header: 'CSR Email',
                accessor: 'csrEmail',
                className: generalStyles.tableCell,
                filter: FILTER_TYPES.multiple,
                Filter: MultiSelectColumnFilter,
            },
            {
                Header: 'CSR Access Roles',
                /**
                 *
                 * @param {Object} row
                 * @param {string[]} row.csrAccessRoles
                 * @returns
                 */
                accessor: (row) => row.csrAccessRoles?.join(', \n') ?? '',
                id: 'csrAccessRoles',
                className: generalStyles.tableCell,
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: 'Change Type',
                accessor: 'actionCode',
                className: generalStyles.tableCell,
                filter: FILTER_TYPES.multiple,
                Filter: MultiSelectColumnFilter,
            },
            {
                Header: 'NS Company Id',
                accessor: 'nsCompanyId',
                className: generalStyles.tableCell,
                Cell: ({ row, value }) => (
                    <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                        {value}
                    </Link>
                ),
                filter: FILTER_TYPES.multiple,
                Filter: MultiSelectColumnFilter,
            },
            {
                Header: 'Byzzer User Email',
                /**
                 *
                 * @param {Object} row
                 * @param {string} row.email
                 * @returns
                 */
                accessor: 'email',
                Cell: ({ row, value }) => (
                    row.original.nsUserId ?
                        <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}/users/${row.original.nsUserId}`}>
                            {value}
                        </Link>
                        : value ?? ''
                ),
                filter: FILTER_TYPES.multiple,
                Filter: MultiSelectColumnFilter,
                className: generalStyles.tableCell,
            },
            {
                Header: 'Old Values',
                accessor: (row) => JSON.stringify(row.oldValues) ?? '',
                id: 'oldValues',
                className: generalStyles.tableCell,
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: 'New Values',
                accessor: (row) => JSON.stringify(row.newValues) ?? '',
                id: 'newValues',
                className: generalStyles.tableCell,
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Header: 'Change Metadata',
                accessor: (row) => JSON.stringify(row.actionMetadata) ?? '',
                id: 'actionMetadata',
                className: generalStyles.tableCell,
                disableFilters: true,
                disableSortBy: true,
            },
        ],
        []
    );

    useEffect(() => {
        loadCsrAuditLogs();
    }, []);

    return (
        <>
            <h1>CSR Audit Logs</h1>
            <hr />
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable
                            data={reportResult}
                            columns={csrAuditLogsReportColumns}
                            downloadFileName={'CsrAuditLogsReport'}
                            withDownload
                        />
                    )
                )}
            </div>
        </>
    );
}
